/* eslint-disable no-undef */
import React from 'react';
import { withProps, compose } from 'recompose';
import styled, { keyframes } from 'styled-components';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import markerIcon from 'assets/icons/marker.svg';
import shopsIcon from 'assets/icons/sklep.svg';
import restaurantsIcon from 'assets/icons/restauracja.svg';
import schoolIcon from 'assets/icons/szkola.svg';
import kindergartenIcon from 'assets/icons/przedszkola.svg';
import fitnessIcon from 'assets/icons/silownia.svg';
import bikeIcon from 'assets/icons/rower.svg';
import subwayIcon from 'assets/icons/metro.svg';
import busIcon from 'assets/icons/autobus.svg';
import tramIcon from 'assets/icons/tramwaj.svg';
import veturiloIcon from 'assets/icons/veturilo.svg';
import hospitalIcon from 'assets/icons/szpital.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const mapStyles = [
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200',
      },
      {
        saturation: -61.8,
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF',
      },
      {
        saturation: -13.200000000000003,
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
];
const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loader = styled.figure`
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid ${({ theme }) => theme.colors.green};
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s ease infinite;
`;

const MapLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);

const getIcon = type => {
  switch (type.toLowerCase()) {
    case 'inwestycja':
    case 'biuro sprzedaży':
      return markerIcon;
    case 'sklepy':
      return shopsIcon;
    case 'restauracje':
      return restaurantsIcon;
    case 'przedszkola':
      return kindergartenIcon;
    case 'szkoły':
      return schoolIcon;
    case 'tramwaj':
      return tramIcon;
    case 'metro':
      return subwayIcon;
    case 'silownia':
      return fitnessIcon;
    case 'szpital':
      return hospitalIcon;
    case 'stacja rowerowa':
      return veturiloIcon;
    case 'autobus':
      return busIcon;
    case 'ścieżka rowerowa':
      return bikeIcon;
    default:
      return null;
  }
};

const MapContainer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAswkjqTgGz6s6TA7JsI-LB6ltHQlfLEQ4&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <MapLoader />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  return (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{
        lat: Number(props.pins[0].mainPageS5PushpinElementLat),
        lng: Number(props.pins[0].mainPageS5PushpinElementLng),
      }}
      defaultOptions={{ styles: mapStyles }}
    >
      {props.pins.map(
        ({
          mainPageS5PushpinElementLat,
          mainPageS5PushpinElementLng,
          mainPageS5PushpinElementDescription,
          mainPageS5PushpinElementImg,
        }) => (
          <Marker
            key={mainPageS5PushpinElementDescription}
            position={{
              lat: Number(mainPageS5PushpinElementLat),
              lng: Number(mainPageS5PushpinElementLng),
            }}
            title={mainPageS5PushpinElementDescription}
            icon={{
              url: getIcon(mainPageS5PushpinElementImg),
              anchor: new google.maps.Point(24, 48),
              scaledSize:
                mainPageS5PushpinElementImg === 'inwestycja'
                  ? new google.maps.Size(55, 55)
                  : new google.maps.Size(35, 35),
            }}
          />
        ),
      )}
    </GoogleMap>
  );
});
export default MapContainer;
