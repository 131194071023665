import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  title,
  description,
  lang = 'pl',
  meta = [],
  ogTitle,
  ogDescription,
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  robots = 'index, follow',
}) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = description || siteMetadata.description;

  const defaultMeta = [
    {
      property: `robots`,
      content: robots,
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: ogTitle || title,
    },
    {
      property: `og:description`,
      content: ogDescription || metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: twitterTitle || title,
    },
    {
      name: `twitter:description`,
      content: twitterDescription || metaDescription,
    },
  ];

  if (ogImage) {
    defaultMeta.push({ name: `og:image`, content: ogImage });
  }

  if (twitterImage) {
    defaultMeta.push({ name: `twitter:image`, content: twitterImage });
  }

  const combinedMeta = meta ? defaultMeta : [...defaultMeta, ...meta];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      link={[
        {
          rel: 'canonical',
          href: 'https://matexipolska/sokratesa-11a',
        },
      ]}
      meta={combinedMeta}
    />
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
